.payouts {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  background-color: #f7f7f7;

  .sidepanel {
    z-index: 2;
  }

  .payout-sidepanel {
    z-index: 1;

    padding: 80px 28px;
    width: 350px;

    font-family: $font-mplusrounded;

    background-color: #ffffff;
    border-top-right-radius: 48px;
    box-shadow: #ffffff -100px 0 0;

    text-align: center;

    h2 {
      font-weight: 400;
      color: $default-text;
    }

    .upcoming-payout-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 0;

      background: linear-gradient(
        180deg,
        rgba(236, 239, 248, 0.45) 0%,
        rgba(248, 249, 252, 0.45) 100%
      );
      border-radius: 8px;

      .upcoming-payout-amount {
        font-size: 26px;
        font-weight: 500;
        line-height: 39px;
        color: $midnight-blue;
      }

      .upcoming-payot-label {
        margin-top: 2px;

        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 24px;
      }

      .paid-payouts-label {
        margin-top: 8px;

        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 24px;
        color: $icon;
      }
    }

    .payout-date-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 36px 0;
      gap: 4px;

      .payout-date {
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 1.5px;
        line-height: 14.85px;
        color: $medium-gray;

        text-transform: uppercase;
      }

      .payout-date-container {
        display: flex;
        align-items: center;

        .payout-date-icon {
          color: $icon;
        }

        .payout-date {
          margin-left: 10px;

          font-size: 16px;
          font-weight: 500;
          letter-spacing: -0.02em;
          line-height: 24px;
          color: $midnight-blue;
        }
      }

      .payout-date-label {
        margin: 2px 0 0 8px;

        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 24px;
        color: $default-text;
      }
    }
  }
}

.payouts-content,
.payout-history-content {
  padding: 38px;
  overflow: hidden;

  flex: 1;

  .datatable-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;

    font-family: $font-mplusrounded;

    background-color: #ffffff;
    border-radius: 12px;

    flex: 1;

    .datatable-header {
      padding: 0 36px;
      border-bottom: 2px solid $zircon-light;
      margin-bottom: 16px;
    }

    .datatables-actions-wrapper {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding: 0;
      margin-top: 12px;

      .export-button {
        display: flex;
        align-items: center;
        margin-left: 16px;

        background: transparent;
        border: none;
        outline: none;

        cursor: pointer;

        .export-icon {
          margin-right: 6px;

          color: $link;
        }

        .export-label {
          font-family: $font-mplusrounded;
          font-size: 14px;
          font-weight: 500;
          color: $link;
        }
      }
    }

    .payout-types {
      display: flex;
      margin-top: 24px;

      .payout-type {
        display: flex;
        justify-content: center;
        padding: 10px 0;
        width: 268px;

        background-color: $gainsboro;

        cursor: pointer;
        span {
          font-size: 16px;
          font-weight: 500;
          letter-spacing: -0.02em;
          line-height: 24px;
          color: $placeholder;
        }

        &:first-child {
          border-top-left-radius: 12px;
        }

        &:not(:first-child) {
          box-shadow: $gainsboro -24px 0 0;
        }

        &:last-child {
          border-top-right-radius: 12px;
        }

        &.active {
          z-index: 1;

          background-color: #dee5ff;
          border-top-right-radius: 12px;
          border-top-left-radius: 12px;

          span {
            color: $default-text;
          }
        }
      }
    }

    .rdt_Table {
      padding: 0 36px;

      .rdt_TableHead {
        .rdt_TableHeadRow {
          background-color: $midnight-blue;

          border-radius: 4px;

          min-height: 40px;

          .rdt_TableCol {
            color: $white;

            .rdt_TableCol_Sortable {
              color: $white;
            }
          }
        }
      }

      .rdt_TableBody {
        .rdt_TableCell {
          font-weight: 700;
        }
      }
    }

    .rdt_Pagination > div {
      border-radius: 4px !important;
      border: 1px solid $medium-gray !important;
    }
  }
}

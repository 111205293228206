.content-moderator {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  background-color: #f7f7f7;

  .content {
    padding: 38px;
    overflow: auto;

    flex: 1;

    .search-wrapper {
      position: relative !important ;

      display: inline-block !important;

      input {
        padding-left: 40px;
        width: 200px;
        height: 40px;

        border-radius: 20px;
      }
    }

    .search-icon {
      position: absolute;
      top: 50%;
      left: 10px;

      width: 20px;
      height: 20px;

      transform: translateY(-50%);
    }
  }

  .content-wrapper {
    min-height: 40rem;

    background-color: #ffffff;
  }

  .datatable-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;

    font-family: $font-mplusrounded;

    background-color: #ffffff;
    border-radius: 12px;

    flex: 1;

    > div:nth-child(1) {
      height: 100%;
      > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        height: 100%;

        .rdt_TableBody {
          overflow: auto;

          flex: 1;
        }
      }
    }
  }

  .datatable-header {
    display: flex;
    justify-content: space-between;
    padding: 40px 48px;
    padding-bottom: 24px;

    border-bottom: 5px solid #f2f4f6;

    .datatable-header-label-wrapper {
      display: flex;
      flex-direction: column;

      font-family: $font-mplusrounded;

      .datatable-header-label {
        font-size: 24px;
        line-height: 36px;
        color: $default-text;
      }

      .datatable-header-description {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: $dark-gray;
      }
    }

    .datatables-actions-wrapper {
      display: flex;
      align-items: center;
      padding: 0;

      .export-button {
        display: flex;
        align-items: center;
        margin-left: 16px;

        background: transparent;
        border: none;
        outline: none;

        cursor: pointer;

        .export-icon {
          margin-right: 6px;

          color: $link;
        }

        .export-label {
          font-family: $font-mplusrounded;
          font-size: 14px;
          font-weight: 500;
          color: $link;
        }
      }
    }
  }

  .posts-header {
    display: grid;
    padding: 0.75rem 1.5rem;

    font-size: 0.875rem;
    color: white;

    background: #1f1a54;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    grid-template-columns: 4fr 3fr 3fr 2fr;
  }

  .posts-list {
    display: flex;
    flex-direction: column;
    max-height: 35rem;

    overflow-y: auto;
    gap: 0.25rem;
  }

  .post-card {
    display: grid;
    align-items: center;
    padding: 1rem 1.5rem;

    border-bottom: 1px solid #f2f4f6;

    transition: background-color 0.2s;

    grid-template-columns: 4fr 3fr 3fr 2fr;
  }

  .post-card:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .post-info {
    display: flex;
    align-items: center;

    gap: 0.75rem;
  }

  .post-image {
    width: 3rem;
    height: 3rem;
    overflow: hidden;

    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.25rem;
  }

  .post-image img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  .post-details {
    text-align: left;
  }

  .post-title {
    display: flex;
    align-items: center;

    font-size: 16px;

    gap: 0.5rem;
  }

  .post-title span {
    font-weight: 500;
  }

  .post-title .verified {
    padding: 0.125rem 0.375rem;

    font-size: 0.75rem;

    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 9999px;
  }

  .post-description {
    margin: 5px 0 0 0;

    font-size: 12px;
  }

  .author-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 0.75rem;
  }

  .author-avatar {
    width: 2rem;
    height: 2rem;
    overflow: hidden;

    border-radius: 9999px;
  }

  .author-avatar img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  .author-details {
    text-align: center;
  }

  .author-name {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 0.875rem;
    font-weight: 500;

    gap: 0.25rem;
  }

  .author-handle {
    font-size: 0.75rem;
    text-align: center;
  }

  .verified-badge {
    color: #9b87f5;

    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
  }

  .post-date-main {
    font-size: 12px;
  }

  .post-date-sub {
    font-size: 9px;
  }

  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
  }

  .no-post {
    margin-top: 10rem;

    text-align: center;
  }

  .flag-title {
    font-size: 18px;
  }

  .flag-desc {
    padding-top: 10px;
    margin: auto;
    width: 29%;

    font-size: 14px;
    text-align: center;
  }

  .actions {
    display: flex;

    gap: 5px;
  }

  .archive-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    width: 100%;

    color: #3057ba;
    text-align: center;

    background: #dee5ff;
    border: none;
    border-radius: 4px;

    cursor: pointer;

    gap: 3px;
  }

  .warning-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    width: 100%;

    color: #ea646c;
    text-align: center;

    background: #ffedf1;
    border: none;
    border-radius: 4px;

    cursor: pointer;

    gap: 3px;
  }

  em {
    background: #ffd403;
  }

  .is-archived,
  .is-suspended {
    padding: 6px;
    width: 100%;

    font-size: 12px;
    text-align: center;

    background: #f6f6f6;
    border-radius: 4px;
  }

  .menu-action {
    cursor: pointer;
  }

  /* Dropdown container */
  .dropdown-container {
    position: relative;
  }

  /* Button styling */
  .dropdown-button {
    padding: 5px 10px;

    font-size: 14px;
    color: white;

    background-color: #007bff;
    border: none;
    border-radius: 4px;

    cursor: pointer;
  }

  /* Dropdown menu */
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: -12px;
    z-index: 99999;

    width: 192px;

    background: white;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .dropdown-menu {
    padding: 16px;
    .title {
      font-size: 12px;
      text-align: center;
    }

    .unarchive {
      color: #3057ba;
    }

    .unsuspend {
      color: #ea646c;
    }

    .delete-user {
      color: white;

      background: #ea646c;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 8px;
      margin-top: 3px;
      margin-bottom: 3px;
      width: 100%;

      text-align: center;

      background: white;
      border: none;
      border-radius: 4px;

      cursor: pointer;

      gap: 3px;
    }
  }

  .dropdown-menu a:hover {
    background-color: #f0f0f0;
  }

  .dropdown-menu::before {
    content: '';

    position: absolute;
    top: -11px;
    right: 10px;

    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #cccccc transparent;
    border-style: solid;
  }

  .dropdown-menu::after {
    content: '';

    position: absolute;
    top: -11px;
    right: 9px;

    border-width: 2px 11px 10px 11px;
    border-color: transparent transparent white transparent;
    border-style: solid;
  }

  .confirmation-modal {
    .title {
      //styleName: 24px / Display 5;
      font-size: 24px;
      font-weight: 400;
      line-height: 35.64px;
      text-align: left;
    }

    .sub-title {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.10000000149011612px;
      line-height: 18px;
      text-align: left;
    }

    .select-wrap {
      position: relative;

      padding: 0 5px;
      margin: 3rem auto 0 auto;
      width: 466px;

      background-color: #ffffff;
      border: 1px solid #dadce0;
      border-radius: 4px;
    }
    .select-wrap label {
      position: absolute;
      top: 6px;

      padding: 0 8px;

      font-size: 8px;
      color: #777777;
      text-transform: uppercase;
    }

    select {
      padding: 0 4px;
      margin-top: 5px;
      width: 100%;
      height: 50px;

      font-size: 16px;

      background-color: #ffffff;
      border: 0;

      &:focus-visible {
        border: none;
        outline: none;
        box-shadow: none;
      }

      option {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 23.76px;
        text-align: left;
      }
    }

    .message {
      padding: 8px;
      margin: auto 0;
      width: 466px;
      height: 98px;

      border: 1px solid #dadce0;
      border-radius: 4px 0 0 0;
    }

    .message-container {
      margin-top: 1rem;

      text-align: center;
    }

    .modal-action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      gap: 4px;

      .confirm {
        color: white;

        background: #ea646c;
      }

      .archive,
      .suspend {
        background: #ffd403;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 27px;
        margin-top: 3px;
        margin-bottom: 3px;
        width: 260px;

        text-align: center;

        background: white;
        border: none;
        border-radius: 4px;

        cursor: pointer;

        gap: 3px;
      }
    }
  }

  .search-flag-wrapper {
    display: flex;
    align-items: center;

    gap: 10px;

    .manage-flag {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.25px;
      line-height: 20.79px;
      color: #3781fc;
      text-align: left;

      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      a {
        text-decoration: none;
      }
    }
  }
}

.flag-title {
  padding: 10px 0;

  font-size: 16px;
  font-weight: 500;
}

.flag-container {
  margin: 20px 0;
}

.success {
  color: black;

  background-color: #ffd400 !important;
}

.success-wrap,
.message-reason {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 30rem;

  label {
    font-size: 14px;
    color: #666666;

    &:first-child {
      margin-right: 8px;
    }
  }

  div {
    font-size: 14px;
    color: #333333;
    text-align: right;
  }
}

.message-reason label::after {
  content: ' (Optional)';

  color: #666666;
}

.icon-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.modal-wrapper {
  padding: 16px 32px 25px !important;
}

.keyword-input-container {
  margin: 19px auto;
}

.keyword-input-container label {
  display: block;
  margin-bottom: 10px;

  font-size: 14px;
  color: #555555;
}

.keyword-box {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}

.keyword-input-wrapper {
  height: 20rem;

  border: 1px solid #cccccc;
  border-radius: 4px;
}

.keyword-chip {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin: 5px;
  //styleName: Global/Text/Caption/Caption (Medium);

  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4000000059604645px;
  line-height: 18px;
  color: #333333;
  text-align: left;

  border: 1px solid #dadce0;

  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.keyword-input-container {
  margin: 19px auto;
}

.keyword-input-container label {
  display: block;
  margin-bottom: 10px;

  font-size: 14px;
  color: #555555;
}

.keyword-chip .remove-button {
  margin-left: 8px;

  font-size: 16px;
  color: #555555;

  background: none;
  border: none;

  cursor: pointer;
}

.keyword-chip .remove-button:hover {
  color: #ff5c5c;
}

.keyword-box input {
  padding: 5px;
  min-width: 150px;
  height: 2rem;

  font-size: 14px;

  background-color: transparent;
  border: none;
  outline: none;

  flex: 1;
}

.keyword-box input::placeholder {
  color: #bbbbbb;
}

.keyword-box input:focus {
  outline: none;
}

.keyword-chip .remove-button {
  margin-left: 8px;

  font-size: 16px;
  color: #555555;

  background: none;
  border: none;

  cursor: pointer;
}

.keyword-chip .remove-button:hover {
  color: #ff5c5c;
}

.keyword-box input {
  padding: 5px;
  min-width: 150px;
  height: 2rem;

  font-size: 14px;

  background-color: transparent;
  border: none;
  outline: none;

  flex: 1;
}

.keyword-box input::placeholder {
  color: #bbbbbb;
}

.keyword-box input:focus {
  outline: none;
}

.message.error {
  color: red;
}

.message {
  margin-top: 12px;

  font-size: 14px;
  color: green;
}

.save-button {
  padding: 10px 20px;
  margin-top: 12px;

  font-size: 16px;
  color: white;

  background-color: #007bff;
  border: none;
  border-radius: 4px;

  cursor: pointer;
}

.save-button:disabled {
  background-color: #cccccc;

  cursor: not-allowed;
}

.dashboard {
  display: flex;
  height: 100%;
  min-height: 100vh;

  background-color: #f7f7f7;

  .content {
    display: flex;
    width: 100%;
    overflow: auto;
  }

  .panel-wrapper {
    display: flex;

    background-color: white;
    border-radius: 0 48px 0 0;
    box-shadow: 0 16px 21px 6px rgba(235, 235, 235, .5);
  }

  & ~ footer {
    right: initial;
    left: 52px;
  }
}

.profile-info {
  position: relative;

  padding: 52px 55px 52px 60px;
  margin: auto;

  background-color: $white;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(31, 26, 84, .06);

  .user-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 27px;
  }

  .user-img-holder {
    margin-right: 16px;
    width: 55px;
    height: 55px;

    .img {
      width: 100%;
      height: 100%;

      background-color: $medium-gray;
      border-radius: 50%;

      object-fit: cover;
    }
  }

  .name,
  .date {
    margin: 0;
  }

  .name {
    margin-bottom: 5px;

    font-size: 16px;
  }

  .date {
    font-size: 13px;
  }

  .heading-holder {
    position: relative;

    display: flex;
    align-items: center;
  }

  .title {
    margin-left: 8px;

    font-size: 16px;
  }

  .subtitle {
    position: absolute;
    right: 0;

    font-size: 10px;
  }

  .info-wrapper {
    margin-bottom: 16px;
  }

  .info-list {
    padding: 15px 0 6px;
    margin: 0;

    border-bottom: 1px solid;

    list-style: none;
  }

  .info-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .info-title {
    margin: 0 250px 0 0;

    font-size: 15px;
    font-weight: 600;
  }

  .info-data {
    margin: 0;

    font-size: 14px;
    line-height: 21px;
    text-align: end;
  }

  .button-holder {
    display: flex;
    justify-content: flex-end;
  }

  .btn-outline {
    margin-right: 14px;
  }

  .btn-yellow {
    width: 191px;
  }
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  min-width: 100vw;
  min-height: 100vh;

  &-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: $midnight-gray;

    opacity: .4;
  }

  .button-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .close-btn {
    position: absolute;
    top: 37px;
    right: 37px;
    z-index: 5;

    padding: 0;
    width: auto;
    min-width: auto;
    height: auto;
  }

  .back-btn {
    margin-top: 26px;

    color: $blue;
  }

  .btn-yellow {
    width: 260px;
  }

  .modal-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;

    padding: 16px 32px 73px;
    width: auto;

    background-color: $white;
    border-radius: 8px;

    transform: translate(-50%, -50%);
  }

  .title {
    margin-bottom: 6px;

    font-size: 24px;
  }

  .subtitle {
    margin: 0;

    font-size: 14px;
    color: $dark-gray;
  }

  .text {
    margin: 0;

    font-size: 11px;
    text-align: center;
  }
}

.selfie-modal .image-holder {
  margin: 25px 112px 12px;

  .img {
    width: 500px;
  }
}

.id-modal {
  .modal-wrapper {
    padding: 16px 32px 33px;
  }

  .image-holder {
    margin: 25px 77px 12px;

    .img {
      width: 500px;

      border-radius: 8px;
    }
  }
}

.approve-modal {
  .modal-wrapper {
    padding: 16px 32px 50px;
  }

  .image-holder {
    margin: 25px 148px 15px;
  }
}

.approve-request-modal {
  .modal-wrapper {
    padding: 16px 32px 130px;
  }

  .user-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 37px 265px 26px;

    .image-holder {
      width: 80px;
      height: 80px;
    }

    .img {
      width: 100%;
      height: 100%;

      border-radius: 50%;

      object-fit: cover;
    }

    .verified-icon {
      margin-left: 4px;
    }

    .name {
      margin: 4px 0;

      font-size: 11px;
    }

    .username {
      margin: 0;

      font-size: 9px;
    }
  }

  .verified-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: auto;
    width: 360px;

    list-style: none;
  }

  .verified-item {
    position: relative;

    padding-left: 35px;
    margin-bottom: 15px;
    width: 31%;

    font-size: 13px;

    &:nth-child(odd) {
      margin-right: 33px;
    }

    &:before {
      content: "";

      position: absolute;
      top: 50%;

      display: inline-block;
      margin-left: -35px;
      width: 30px;
      height: 30px;

      background: url("../../assets/images/check-icon.svg") no-repeat center;

      transform: translateY(-50%);
    }
  }
}

.disapprove-modal {
  .modal-wrapper {
    padding: 16px 32px 31px;
  }

  .text {
    margin: 23px 0 15px;

    font-size: 14px;
    text-align: center;
  }

  .reason-holder {
    display: flex;
    flex-wrap: wrap;
    margin: 0 40px 0 11px;
  }

  .checkbox-item {
    margin-bottom: 14px;
    width: 38%;

    font-size: 12.6px;

    &:nth-child(odd) {
      margin-right: 33px;
    }
  }

  .button-holder {
    margin: 0 246px;
  }
}

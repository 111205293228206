
.filter {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  min-width: 100vw;
  min-height: 100vh;

  &:after {
    content: "";

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: $midnight-gray;

    opacity: .4;
  }

  .close-btn {
    position: absolute;
    top: 56px;
    right: 28px;
    z-index: 5;

    padding: 0;
    width: auto;
    min-width: auto;
    height: auto;
  }

  .filter-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    padding: 38px 24px;
    width: 295px;

    background-color: $white;
  }

  .filter-holder {
    margin-bottom: 34px;
  }

  .heading {
    margin-bottom: 3px;

    font-size: 19px;
  }

  .subheading {
    margin: 0 0 40px;

    font-size: 13px;
    color: $grey-suit;
  }

  .title {
    font-size: 14px;
  }

  .list {
    padding: 0;
    margin: 0;

    list-style: none;
  }

  .list-item {
    margin: 17px 0;
  }

  .checkbox-item,
  .radio-item {
    padding: 9px 38px 9px 16px;
  }

  .checkmark {
    right: 18px;
    left: initial;
  }
}

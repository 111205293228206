.input {
  padding: 8px;

  background: #ffffff;
  border: 1px solid #dadce0;
  border-radius: 4px;

  box-sizing: border-box;
}

.checkbox-item,
.radio-item {
  position: relative;

  display: flex;
  align-items: center;
  padding: 16px 16px 16px 50px;

  font-family: $font-mplusrounded;
  font-size: 15px;
  font-weight: 600;

  background-color: $snow;

  cursor: pointer;

  &:hover .checkbox ~ .checkmark,
  .checkbox:checked ~ .checkmark,
  &:hover .radio ~ .checkmark,
  .radio:checked ~ .checkmark {
    border-color: $light-orange;
  }

  .checkbox,
  .radio {
    position: absolute;

    width: 0;
    height: 0;

    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 18px;

    width: 19px;
    height: 19px;

    background-color: $white;
    border: 1px solid $medium-gray;

    transform: translateY(-50%);

    &:after {
      content: "";

      position: absolute;
      top: 50%;
      left: 50%;

      display: none;

      border: solid $light-orange;
    }
  }
}

.checkbox-item {
  .checkmark {
    border-radius: 5px;

    &:after {
      width: 5px;
      height: 9px;

      border-width: 0 1px 1px 0;

      transform: rotate(35deg) translate(-110%, -31%);
    }
  }
}

.radio-item {
  .checkmark {
    border-radius: 50%;

    &:after {
      width: 7px;
      height: 7px;

      background-color: $light-orange;
      border-radius: 50%;

      transform: translate(-53%, -53%);
    }
  }
}

.message-box {
  padding: 7px 15px;
  margin: 20px 0 30px;
  width: 100%;
  height: 100px;

  font-family: $font-mplusrounded;
  font-size: 14.5px;

  border: 1px solid $light-blue;
  border-radius: 4px;

  box-sizing: border-box;
  resize: none;

  &:focus {
    border: 1px solid $light-orange;
    outline: none;

    &::placeholder {
      color: $light-orange;
    }
  }
}

.payout-history {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  font-family: $font-mplusrounded;

  background-color: #f7f7f7;

  .sidepanel {
    z-index: 2;
  }

  .payout-history-content {
    padding: 38px;
    overflow: hidden;

    flex: 1;

    .payout-history-content-label {
      padding: 24px 48px;

      background-color: #ffffff;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
    }
  }

  .payout-history-content {
    .datatable-wrapper {
      max-height: calc(100% - 82px);
    }
  }
}

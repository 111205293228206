.input-holder {
  position: relative;

  margin-bottom: 30px;

  .eye-icon {
    display: flex;
    align-items: center;
  }

  img {
    width: 24px;
    height: 24px;
  }
}

.input {
  padding: 25px 15px 7px;
  width: 100%;
  height: 54px;

  font-family: $font-mplusrounded;
  font-size: 16px;

  &:focus {
    border: 1px solid $midnight-gray;
    outline: initial;
  }

  &:focus ~ .placeholder,
  &:not([value='']):not(:focus):valid ~ .placeholder,
  &:not([value='']):not(:focus):invalid ~ .placeholder {
    top: 5px;
    bottom: 10px;
    left: 16px;

    font-size: 14px;

    opacity: 1;
  }
}

.textarea {
  padding: 25px 15px 7px;
  width: 100%;
  height: 54px;

  font-family: $font-mplusrounded;
  font-size: 16px;

  &:focus {
    border: 1px solid $midnight-gray;
    outline: initial;
  }

  &:focus ~ .placeholder,
  &:not(:empty):not(:focus):valid ~ .placeholder,
  &:not(:empty):not(:focus):invalid ~ .placeholder {
    top: 5px;
    bottom: 10px;
    left: 16px;

    font-size: 14px;

    opacity: 1;
  }
}

.placeholder {
  position: absolute;
  top: 15px;
  left: 16px;

  font-family: $font-mplusrounded;
  font-size: 17px;
  color: $gray;

  pointer-events: none;
  transition: 0.2s ease all;
}

textarea {
  resize: none;
}

// CUSTOM STYLES
.error-message {
  font-size: 12px;
  padding-left: 1rem;
  color: red;
}

.input-error {
  border: 1px solid red;
}

.card {
  padding: 25px;
  margin: 10px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(31, 26, 84, 0.06);
}

.footer {
  padding: 30px 0;

  text-align: center;

  p {
    margin: 0;

    color: $medium-gray;
  }

  .copyright {
    font-size: 14px;
  }

  .text {
    font-size: 11px;

    &:nth-of-type(2) {
      margin: 0 0 17px;
    }

    &:nth-of-type(3) {
      color: $silver;
    }
  }
}

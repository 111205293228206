//font embed
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500&display=swap');
$open-sans: 'Open Sans', sans-serif;
$font-mplusrounded: 'M PLUS Rounded 1c', sans-serif;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '../assets/' !default;
$background-color: #e5e5e5;
$white: #ffffff;
$solitude: #f2f7ff;
$blue: #3781fc;
$link: #3781fc;
$light-blue: #dadce0;
$pale-blue: #e9edf1;
$midnight-blue: #1f1a54;
$midnight-gray: #2e3034;
$gray: #515057;
$light-gray: #f6f6f6;
$medium-gray: #91919c;
$dark-gray: #656565;
$silver: #c5c5c5;
$snow: #fbfbfb;
$grey: #393b4d;
$grey-suit: #8c8b98;
$yellow: #ffd400;
$light-orange: #f6a80f;
$lavender-blush: #ffedf1;
$brink-pink: #ea646c;
$icon: #91919c;
$default-text: #2e3034;
$gainsboro: #e8e8e8;
$placeholder: #515057;
$zircon-light: #f2f4f6;
$mischka: #a8aab7;
$shamrock: #36c9aa;
$mountain-meadow: #00bb94;

.color-icon {
  color: $icon;
}

.payout-details {
  display: flex;
  flex-direction: column;
  padding: 56px;
  width: 100%;
  height: 100%;
  overflow: auto;

  font-family: $font-mplusrounded;

  background-color: #f7f7f7;

  .final-payout-amount {
    font-weight: 500;
  }

  h3 {
    display: flex;
    align-items: center;
    margin: 0;

    font-size: 18px;
    font-weight: 500;
    letter-spacing: .15px;
    line-height: 27px;
    color: $default-text;
  }

  .post-orders-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .post-orders-total {
    font-family: Rounded Mplus 1c;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -.02em;
    line-height: 27px;
    color: $midnight-blue;
    text-align: right;
  }

  .post-orders-total-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    width: 280px;

    background: linear-gradient(
    180deg,
    rgba(236, 239, 248, .45) 0%,
    rgba(248, 249, 252, .45) 100%
    );
    border-radius: 8px;
  }

  .post-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;

    border-radius: 100%;

    &.need {
      background-color: $shamrock;
    }

    &.sell {
      background-color: $blue;
    }

    &.service {
      background-color: $brink-pink;
    }
  }

  .payout-post-order-status {
    padding: 4px 0;
    width: 75px;

    font-size: 11px;
    letter-spacing: .271486px;
    line-height: 11px;
    color: #ffffff;
    text-align: center;
    text-transform: capitalize;

    border-radius: 11px;

    &.pending {
      background-color: $icon;
    }

    &.paid,
    &.completed {
      background-color: $shamrock;
    }
  }

  .post-type-label {
    margin-left: 6px;
  }

  .content-header {
    position: relative;
    .payout-details-logo {
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
    }

    .back-button-wrapper {
      display: flex;
      align-items: center;

      background: none;
      border: none;
      outline: none;

      cursor: pointer;
    }

    .back-button {
      display: flex;
      padding: 8px;
      margin-right: 24px;

      background-color: $midnight-blue;
      border: none;
      border-radius: 100%;
      outline: none;

      .back-icon {
        color: #ffffff;
      }
    }

    .back-button-label {
      font-size: 16px;
      letter-spacing: .5px;
      line-height: 24px;
    }
  }

  .payout-details-content {
    margin-top: 32px;

    background-color: #ffffff;
    border-radius: 12px;
  }

  .payout-details-header {
    padding: 24px 55px;

    border-bottom: 5px solid $zircon-light;

    .header-title {
      margin-bottom: 8px;

      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      color: $default-text;
    }

    .header-subtitle {
      font-family: Rounded Mplus 1c;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: .1px;
      line-height: 18px;
      color: $dark-gray;
    }
  }

  .section {
    padding: 24px;
    margin: 28px 84px;

    border: 1px solid #cacbcc;
    border-radius: 8px;
  }

  .section-label-icon {
    margin-right: 8px;
  }

  .user-details-section {
    display: flex;
  }

  .user-details {
    flex: 1;
    .user-details-column {
      display: flex;
      margin-top: 18px;
      margin-bottom: 12px;

      span {
        font-size: 14px;
        letter-spacing: .25px;
        line-height: 21px;
        color: #515057;
      }

      .user-details-column-label {
        width: 225px;

        font-weight: 500;
        color: $default-text;

        flex: none;
      }

      .user-details-column-value {
        display: flex;
        flex-direction: column;

        flex: 1;
      }

      .read-more,
      .read-less {
        display: block;

        color: $link;
      }
    }
  }
  .payout-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;

    .status-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
      width: 100%;

      background: linear-gradient(
      180deg,
      rgba(236, 239, 248, .45) 0%,
      rgba(248, 249, 252, .45) 100%
      );
      border-radius: 8px;
    }

    .status-label-wrapper {
      display: flex;
      align-items: center;
    }

    .status-indicator {
      margin-right: 10px;
      width: 8px;
      height: 8px;

      background-color: $mischka;
      border-radius: 100%;
    }

    .payout-status-label {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -.02em;
      line-height: 36px;
      color: $midnight-blue;
      text-transform: capitalize;
    }

    .status-sub {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: .1px;
      line-height: 18px;
      color: #91919c;
    }

    .release-payout-button {
      padding: 12px;
      margin-top: 8px;
      width: 100%;

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -.02em;
      line-height: 24px;
      color: #ffffff;

      background: #1f1a54;
      border: 1.5px solid #1f1a54;
      border-radius: 4px;

      cursor: pointer;
    }

    .payout-breakdown-container {
      padding: 16px 24px 16px 16px;
      margin-top: 20px;
      width: 100%;

      border: 1px solid $zircon-light;
      border-radius: 4px;

      .payout-breakdown-header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        height: 40px;

        .summary-icon {
          margin-right: 8px;
          width: 16px;
          height: 16px;
        }

        .payout-breakdown-title {
          font-size: 16px;
          font-weight: 500;
          letter-spacing: -.02em;
          line-height: 24px;
          color: $default-text;
        }
      }

      .payout-breakdown-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item-label {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: .25px;
          line-height: 21px;
          color: $default-text;
        }

        .item-value {
          font-size: 14px;
          letter-spacing: .25px;
          line-height: 21px;
          color: $placeholder;
        }
      }

      .payout-breakdown-item + .payout-breakdown-item {
        margin-top: 16px;
      }
    }

    .payout-amount-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
      margin-top: 8px;
      width: 100%;

      background: linear-gradient(
      180deg,
      rgba(236, 239, 248, .45) 0%,
      rgba(248, 249, 252, .45) 100%
      );
      border-radius: 8px;

      .payout-amount {
        /* identical to box height */
        display: flex;
        align-items: center;

        font-size: 24px;
        font-weight: 500;
        letter-spacing: -.02em;
        line-height: 36px;
        color: $midnight-blue;
      }

      .payout-amount-sub {
        display: flex;
        align-items: center;

        font-size: 14px;
        font-weight: 500;
        letter-spacing: .1px;
        line-height: 18px;
        color: $icon;
        text-align: center;
      }
    }
    &.paid,
    &.completed {
      .status-indicator {
        background-color: $shamrock;
      }
    }
  }
}

.release-payout-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, .25);

  opacity: 1;
}

.release-payout-modal {
  position: relative;

  padding: 32px 28px;
  width: 690px;

  font-family: $font-mplusrounded;

  background: #ffffff;
  border-radius: 8px;

  .close-button {
    position: absolute;
    top: 27px;
    right: 32px;

    display: flex;
    padding: 4px;

    background: transparent;
    border: none;
    outline: none;

    cursor: pointer;

    .close-button-icon {
      color: $icon;
    }
  }

  h2 {
    margin: 0;

    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    color: $default-text;
  }

  h5 {
    margin: 0;
    margin-top: 6px;

    font-size: 14px;
    font-weight: 500;
    letter-spacing: .1px;
    line-height: 18px;
    color: #656565;
  }

  .release-payout-modal-content {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }

  .release-payout-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 22px;
    margin-top: 32px;

    background: rgba(242, 244, 246, .5);
    border-radius: 4px;

    .release-payout-details-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 40ch;
    }

    span {
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      letter-spacing: .5px;
      line-height: 24px;
      color: $midnight-blue;
      text-align: center;

      &.release-payout-amount {
        margin: 14px 0;

        font-size: 36px;
        font-weight: 500;
        letter-spacing: -.02em;
        line-height: 53px;
      }

      &.seller-name {
        font-weight: 500;
        letter-spacing: -.02em;
        color: #1f1a54;
      }
    }
  }

  .release-payout-inputs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 18px;

    .release-payout-inputs-wrapper {
      width: 100%;
      max-width: 320px;
    }

    .input-char-counter {
      display: flex;
      justify-content: flex-end;

      span {
        font-size: 12px;
        line-height: 18px;
        color: $mischka;
      }
    }

    textarea {
      max-height: 150px;
    }
  }

  .release-payout-buttons-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .confirm-release-button {
      &:disabled {
        opacity: .7;
        cursor: default;
      }
    }

    button {
      padding: 12px;
      width: 100%;
      max-width: 320px;

      font-family: $font-mplusrounded;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -.02em;
      line-height: 24px;
      text-align: center;

      border: none;
      border-radius: 4px;

      cursor: pointer;
    }
  }

  .confirm-release-button {
    margin-bottom: 12px;

    color: $default-text;

    background: $yellow;
  }

  .cancel-button {
    color: $link;

    background: transparent;
  }

  .table-container {
    .rdt_Table {
      margin-top: 48px;

      .rdt_TableCol_Sortable {
        background-color: red;
      }
    }
  }
}

.send-notification-wrapper {
  display: flex;
  height: 100%;
  .panel-wrapper {
    display: flex;

    background-color: white;
    border-radius: 0 48px 0 0;
    box-shadow: 0 16px 21px 6px rgba(235, 235, 235, .5);
  }

  .content {
    position: relative;
    z-index: 100;

    padding: 16px;
    width: 100%;
    overflow: auto;
  }

  .spinner-wrapper {
    z-index: 20000;
  }

  .content-length-wrapper {
    display: flex;
    flex-direction: row-reverse;

    span {
      margin-top: 8px;

      font-size: 12px;
    }
  }

  .input-label {
    display: block;
    margin-bottom: 8px;

    font-size: 16px;
  }

  & ~ footer {
    right: initial;
    left: 52px;
  }

  .modal {
    z-index: 1000;
  }


  .modal-wrapper {
    padding: 32px;
    width: 420px;
    max-width: 420px;


    span {
      font-size: 14px;
      line-height: 20px;
    }
    .title {
      padding-top: 12px;
      padding-bottom: 12px;

      font-weight: bold;
    }

    .body {
      font-size: 14px;
      line-height: 20px;
    }

    .preview-wrapper {
      display: flex;
      flex-direction: column;
      margin-right: 24px;
    }

    .preview-content {
      margin-left: 44px;
    }

    .push-notification-content {
      display: flex;
      flex-direction: column;
    }

    .app-name-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .app-name {
      margin-left: 12px;
    }

    .servbees-icon-wrapper {
      display: flex;

      border-radius: 50%;
      img {
        width: 32px;
        height: 32px;
      }
    }

    .send-button {
      margin-top: 64px;
      width: 100px;
    }
  }
}
